import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/LocalStorage';

export const callService = async (uri, obj) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + uri, obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getService = async (uri) => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.BASE_API + uri,{
      headers : {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const updateService = async (uri, obj) => {
  let token = LocalStorage.getAccessToken();
  return axios.put(
    config.BASE_API + uri, obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const deleteService = async(uri) => {
  let token = LocalStorage.getAccessToken();
  return axios.delete(
    config.BASE_API + uri, {
      headers : {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getOrg = async() => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + 'organizations/get',{},
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getApp = async() => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + 'applications',{},
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const userDetail = async (uri,obj) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + uri,
    obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}